import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/UseAuthContext';
import { FaArrowLeft } from 'react-icons/fa';
import { toast } from 'wc-toast';
import { useNavigate } from 'react-router-dom';

function TransferConfirm({ transferData, setTransferReady, data }) {
    transferData.symbol=data.symbol
    const navigate = useNavigate();
    const [coinPrice, setCoinPrice] = useState(null);
    const { user } = useAuthContext();
    const [loading, setLoading] = useState(false); // State to track loading

    useEffect(() => {
        const toPrice = (transferData.amount / data.current_price);
        if (data.id === 'ethereum') {
            setCoinPrice(toPrice.toFixed(6));
            transferData.coinAmount=toPrice.toFixed(6)
        } else if (data.id === 'bitcoin') {
            setCoinPrice(toPrice.toFixed(8));
            transferData.coinAmount=toPrice.toFixed(8)
        } else if (data.id === 'tether') {
            setCoinPrice(transferData.amount);
            transferData.coinAmount=toPrice.toFixed(2)
        } else {
            setCoinPrice(toPrice.toFixed(3));
            transferData.coinAmount=toPrice.toFixed(3)
        }
    }, [data]);

    const handleSubmit = async () => {
        if (loading) return; // Prevent sending multiple requests
        setLoading(true); // Set loading to true when the request starts

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/crypto/send-crypto/${data.id}`,
                { transferData },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.token}`,
                    },
                }
            );
            console.log(response.data);
            toast.success("Sent and Pending Check Status in History");
            localStorage.setItem('openChooseSend', false);
            window.location.reload(); // Force a page refresh
        } catch (error) {
            toast.error(error.response?.data?.error || 'An error occurred');
            console.log(error);
        } finally {
            setLoading(false); // Set loading to false after the request completes
        }
    };

    return (
        <div className='confirm-transfer'>
            <div className="choose-receive-top">
                <span onClick={() => {
                    setTransferReady(false);
                }}>
                    <FaArrowLeft />
                </span>
                <p>Transfer</p>
                <span></span>
            </div>

            <div className="amount-data">
                <p>-{coinPrice} {data.symbol}</p>
                <span>{transferData.amount} $</span>
            </div>

            <div className='confirm-asset-data'>
                <div className="asset-data">
                    <p>Asset</p>
                    <span className='asset-name'>{data.id}</span>
                </div>
                <div className="asset-data">
                    <p>From</p>
                    <span>{data.address}</span>
                </div>
                <div className="asset-data">
                    <p>To</p>
                    <span>{transferData.address}</span>
                </div>
            </div>

            <div className="confirm-btn">
                <button
                    onClick={handleSubmit}
                    disabled={loading} // Disable the button while loading
                >
                    {loading ? 'Sending...' : 'Confirm'} {/* Show different text when loading */}
                </button>
            </div>
        </div>
    );
}

export default TransferConfirm;
