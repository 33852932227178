import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaSyncAlt } from 'react-icons/fa';
import { useAuthContext } from '../../hooks/UseAuthContext';
import { toast } from 'wc-toast';

function Swap({ setOpenSwap }) {
  const [tokens, setTokens] = useState([]);
  const [fromToken, setFromToken] = useState(null); 
  const [toToken, setToToken] = useState(null); 
  const [amount, setAmount] = useState(0);
  const { user } = useAuthContext();
  const [fromTokenUsdPrice, setFromTokenUsdPrice] = useState(null);
  const [convertedValue, setConvertedValue] = useState(0);
  const [error, setError] = useState(null);
  const [balances, setBalances] = useState(null);
  const [loading, setLoading] = useState(false);
  // Handle the token swap logic (swap from and to tokens)
  const handleSwapTokens = () => {
    const temp = fromToken;
    setFromToken(toToken);
    setToToken(temp);
  };


  const swap=()=>{
    console.log("this is fromTokenConvertion",Number(fromTokenUsdPrice))
    if (loading) return; // Prevent sending multiple requests
    setLoading(true); // Set loading to true when the request starts
   axios.post(`${process.env.REACT_APP_API_URL}/crypto/swap`,{
    amount:Number(fromTokenUsdPrice),
    from:fromToken.id,
    to:toToken.id

   }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    }).then(response=>{
      toast.success("Swap successful")
      setAmount(0)
      setConvertedValue(0)
    }).catch(error=>{
      console.log(error)
    }).finally(()=>{
      setLoading(false);
    })

  }

  // Fetch tokens and set the initial from/to tokens
  useEffect(() => {
    const fetchData = async () => {
      const cachedData = localStorage.getItem('walletTokens');
      
      if (cachedData) {
        const parsedTokens = JSON.parse(cachedData);
        console.log('This is ', parsedTokens)
        setTokens(parsedTokens);
        setFromToken(parsedTokens[0]);
        setToToken(parsedTokens[1]);
      } else {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/crypto/get-wallet-tokens`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}`
            }
          });
          console.log(response.data)
          setTokens(response.data);
          setFromToken(response.data[0]);
          setToToken(response.data[1]);
          localStorage.setItem('walletTokens', JSON.stringify(response.data));
        } catch (error) {
          console.log(error);
        }
      }
    };

    if (user && user.token) {
      fetchData();
    }
  }, [user]);

  // Fetch user balances
  useEffect(() => {
    const fetchBalances = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-user-balance`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
          }
        });
        setBalances(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (user && user.token) {
      fetchBalances();
    }
  }, [user]);

  // Handle amount change
  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value === '' ? 0 : Number(value));
  };

  // Update converted value and check balance
  useEffect(() => {
    if (balances && fromToken && toToken && amount > 0) {
      const balanceObj = balances.balances.find(bal => bal.token === fromToken.id);
      const balance = balanceObj ? balanceObj.balance : 0;

      const usdValue = amount * fromToken.current_price;
      setFromTokenUsdPrice(usdValue.toFixed(2));

      if (usdValue > balance) {
        setError(`Insufficient ${fromToken.symbol} balance`);
      } else {
        setError(null);
      }

      const toPrice = (amount * fromToken.current_price) / toToken.current_price;
      setConvertedValue(
        toToken.id === 'ethereum' ? toPrice.toFixed(6) :
        toToken.id === 'bitcoin' ? toPrice.toFixed(8) :
        toToken.id === 'tether' ? toPrice.toFixed(2) :
        toPrice.toFixed(3)
      );
    } else {
      setFromTokenUsdPrice(null);
    }
  }, [amount, fromToken, toToken, balances]);

  return (
    <div className='swap-page'>
      <div className="choose-receive-top">
        <span onClick={() => setOpenSwap(false)}>
          <FaArrowLeft />
        </span>
        <p>Swap</p>
        <span></span>
      </div>

      {tokens && fromToken && toToken && (
        <div className='swap-section'>
          {/* From Token */}
          <div className="swap-token">
            <p>From</p>
            <div className="token-input">
            <img
                                src={fromToken.image.startsWith('http') ? fromToken.image : `${process.env.REACT_APP_API_URL}/images/${fromToken.image}`}
                                alt={fromToken.symbol}
                            />
    <select
  id={fromToken.id}
  value={fromToken.id} // Use `id` to uniquely identify the selected token
  onChange={(e) => setFromToken(tokens.find(token => token.id === e.target.value))}
>
  {tokens.map((token) => (
    <option key={token.id} value={token.id}>
      {`${token.symbol} (${token.id})`} 
    </option>
  ))}
</select>


              <input
                type="number"
                placeholder="0"
                value={amount}
                onChange={handleAmountChange}
              />
            </div>
            {fromTokenUsdPrice && <p className='from-usdt-price'>${fromTokenUsdPrice}</p>}
            {error && <span className='swap-error'>{error}</span>}
          </div>

          {/* Swap Button */}
          <div className="swap-btn" onClick={handleSwapTokens}>
            <FaSyncAlt />
          </div>

          {/* To Token */}
          <div className="swap-token">
            <p>To</p>
            <div className="token-input">
            <img
                                src={toToken.image.startsWith('http') ? toToken.image : `${process.env.REACT_APP_API_URL}/images/${toToken.image}`}
                                alt={toToken.symbol}
                            />
               <select
  id={toToken.id}
  value={toToken.id} // Use `id` to uniquely identify the selected token
  onChange={(e) => setToToken(tokens.find(token => token.id === e.target.value))}
>
  {tokens.map((token) => (
    <option key={token.id} value={token.id}>
      {`${token.symbol} (${token.id})`} 
    </option>
  ))}
</select>
              <div>
                <input
                  type="number"
                  placeholder="0"
                  value={convertedValue}
                  readOnly
                />
                {/* <span>{toToken.symbol}</span> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Swap Action */}
      <div className="swap-action">
        {error || amount === 0 ? (
          <button className='disabled-btn' disabled={true}>Swap</button>
        ) : (
          <button onClick={()=>{
            swap()
          }}>{ loading ? 'Swapping...' : 'Confirm'}</button>
        )}
      </div>
    </div>
  );
}

export default Swap;
